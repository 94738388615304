import React, { useState, useEffect, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { BASE_API } from '../../../config';
import AppLoader from '../../../shared/AppLoader';
import CategoryIcon from './CategoryIcon';
import DateFormate from '../../../utils/DateFormate';
import CopiedClipboard from '../../../utils/CopiedClipboard';
import { BsArrowLeft, BsClipboardHeart } from 'react-icons/bs';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { GoIssueClosed } from 'react-icons/go';
import { BiEditAlt, BiTrashAlt } from 'react-icons/bi';
import { toast } from 'react-hot-toast';
import Swal from 'sweetalert2';
import { useForm } from 'react-hook-form';
import { InitializeContext } from '../../../App';

export default function SingleAccount() {
          const { theme } = useContext(InitializeContext);
          const { categoryID, accountID } = useParams();
          const navigate = useNavigate();
          const [showPassword, setShowPassword] = useState<boolean>(false);
          const [isEditAccount, setIsEditAccount] = useState<boolean>(false);
          const { setValue } = useForm();

          useEffect(() => {
                    setValue("account", accountID);
          }, [accountID, setValue]);

          const { data, refetch, isLoading } = useQuery(["account"], async () => {
                    const res = await axios.get(`${BASE_API}/accounts/single-account?categoryID=${categoryID}&accountID=${accountID}`);
                    return res?.data;
          });

          const account = data?.data;

          const handleEditAccount = async (e: React.FormEvent<HTMLFormElement>) => {
                    e.preventDefault();
                    const formData = new FormData(e.currentTarget);
                    const username = formData.get('username');
                    const password = formData.get('password');
                    const sites = formData.get('sites');
                    const notes = formData.get('notes');

                    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
                    const isValidUrl = urlRegex.test(sites?.toString() || '');

                    if (!isValidUrl) {
                              return toast.error('Please enter a valid url');
                    }

                    if (!username || !password || !sites) {
                              return toast.error('Please fill all the fields');
                    } else {
                              const res = await axios.put(`${BASE_API}/accounts/account?categoryID=${categoryID}&accountID=${accountID}`, {
                                        account: {
                                                  username,
                                                  password,
                                                  url: sites,
                                                  notes
                                        }
                              });

                              if (res?.data?.status === 200) {
                                        setIsEditAccount(false);
                                        toast.success(res?.data?.message);
                                        refetch();
                              } else {
                                        toast.error(res?.data?.message);
                              }
                    }
          }

          const handleDelete = async (id: string) => {
                    Swal.fire({
                              title: 'Are you sure?',
                              text: "You won't be able to revert this!",
                              icon: 'warning',
                              showCancelButton: true,
                              background: theme ? '#ffffff' : '#222830',
                              color: theme ? '#000000' : '#ffffff',
                              confirmButtonColor: '#3085d6',
                              cancelButtonColor: '#d33',
                              confirmButtonText: 'Yes, delete it!'
                    }).then(async (result: any) => {
                              if (result.isConfirmed) {
                                        const res = await axios.delete(`${BASE_API}/accounts/account?categoryID=${categoryID}&accountID=${id}`);
                                        if (res?.data?.status === 200) {
                                                  Swal.fire({
                                                            icon: 'success',
                                                            title: 'Deleted!',
                                                            text: res?.data?.message,
                                                            background: theme ? '#ffffff' : '#222830',
                                                            color: theme ? '#000000' : '#ffffff',
                                                            showConfirmButton: false,
                                                            timer: 1500
                                                  })
                                                  navigate(-1);
                                                  refetch();
                                        } else {
                                                  toast.error(res?.data?.message);
                                        }
                              }
                    }
                    )
          }

          if (isLoading) {
                    return <AppLoader />
          }

          return (
                    <div className='py-5'>
                              <h1 className={`text-lg md:text-2xl ${theme ? 'text-black' : 'text-white'} font-semibold`}>
                                        Accounts of <span className={`capitalize ${theme ? 'text-error' : 'text-warning'} cursor-pointer`} onClick={() => navigate(-1)}>{account?.category}</span>
                              </h1>
                              <div className='mt-5'>
                                        <div className='flex items-center w-1/5 md:w-1/12 lg:w-1/12 gap-2 cursor-pointer' onClick={() => navigate(-1)}>
                                                  <BsArrowLeft className={`${theme ? 'text-black' : 'text-white'} text-lg`} />
                                                  <h2 className={`${theme ? 'text-black' : 'text-white'} capitalize text-lg md:text-xl font-semibold`}>Back</h2>
                                        </div>
                              </div>
                              <div className={`${theme ? 'bg-white text-black' : 'bg-[#222830] text-white'} mt-5 md:mt-10 ${isEditAccount ? 'flex flex-col md:flex-row justify-center w-full gap-5 md:gap-20' : 'w-full md:w-2/3 lg:w-2/3 mx-auto'} rounded-2xl shadow-lg p-5`}>
                                        <div className='w-full'>
                                                  <div className='flex justify-between items-center mb-5'>
                                                            <div>
                                                                      {CategoryIcon(account?.category, 50)}
                                                            </div>
                                                            <div className='flex justify-end items-center gap-2'>
                                                                      <button onClick={() => setIsEditAccount(true)} className={`${isEditAccount && 'hidden'} ${theme ? 'bg-white text-black' : 'btn-neutral text-white'} shadow-md btn btn-xs`}>
                                                                                <BiEditAlt />
                                                                      </button>
                                                                      <button onClick={() => handleDelete(account._id)} className={`${isEditAccount && 'hidden'} ${theme ? 'bg-white text-black' : 'btn-neutral text-white'} shadow-md btn btn-xs`}>
                                                                                <BiTrashAlt />
                                                                      </button>
                                                            </div>
                                                  </div>
                                                  <div className='flex justify-center mb-3'>
                                                            <small className='text-xs text-center font-semibold'>Created At: <br />{DateFormate(account?.createdAt)}</small>
                                                  </div>
                                                  {
                                                            account?.updatedAt ? (
                                                                      <div className='flex justify-center mb-5'>
                                                                                <small className='text-xs text-center font-semibold'>Updated At: <br />{DateFormate(account?.updatedAt)}</small>
                                                                      </div>
                                                            ) : (
                                                                      <div className='flex justify-center mb-5'>
                                                                                <small className='text-xs text-center font-semibold'>Updated At: <br />{DateFormate(account?.createdAt)}</small>
                                                                      </div>
                                                            )
                                                  }
                                                  <div className='flex flex-col gap-3'>
                                                            <div className='flex justify-between select-none cursor-not-allowed'>
                                                                      <h1 className='text-xs md:text-sm font-semibold'>Username: <br /><span className={`${theme ? 'text-error' : 'text-warning'}`}>{account?.username}</span></h1>
                                                                      <BsClipboardHeart onClick={() => CopiedClipboard(account?.username)} size={18} className='text-white cursor-pointer' />
                                                            </div>
                                                            <div className='flex justify-between select-none cursor-not-allowed'>
                                                                      <h1 className='text-xs md:text-sm font-semibold'>Password: <br /><span className={`${theme ? 'text-error' : 'text-warning'} cursor-pointer`} onClick={() => setShowPassword(!showPassword)}>{
                                                                                showPassword ? account?.password : '********'
                                                                      }</span></h1>
                                                                      <BsClipboardHeart onClick={() => CopiedClipboard(account?.password)} size={18} className='text-white cursor-pointer' />
                                                            </div>
                                                            <div className='flex justify-between'>
                                                                      <h1 className='text-xs md:text-sm font-semibold'>Sites: <br />
                                                                                <a href={account?.url} target='_blank' rel='noreferrer'>
                                                                                          <span className={`${theme ? 'text-error' : 'text-warning'}`}>
                                                                                                    {account?.url}
                                                                                          </span></a>
                                                                      </h1>
                                                                      <BsClipboardHeart onClick={() => CopiedClipboard(account?.url)} size={18} className='text-white cursor-pointer' />
                                                            </div>
                                                            {account?.notes && (
                                                                      <div className='select-none cursor-not-allowed'>
                                                                                <h1 className='text-xs md:text-sm font-semibold'>Notes: <br />
                                                                                          <span className={`${theme ? 'text-error' : 'text-warning'}`}>
                                                                                                    {account?.notes}
                                                                                          </span>
                                                                                </h1>
                                                                      </div>
                                                            )}
                                                  </div>
                                        </div>
                                        <div className='w-full'>
                                                  {
                                                            isEditAccount && (
                                                                      <>
                                                                                <div className="divider">UPDATE CREDENTIALS</div>
                                                                                <form onSubmit={handleEditAccount}>
                                                                                          <div className='flex flex-col'>
                                                                                                    <label htmlFor='username' className='text-white mb-1'>Username</label>
                                                                                                    <input type='text' name='username' defaultValue={account?.username} placeholder='Type username' className={`${theme ? 'bg-white text-black' : 'bg-[#222830] text-white'} input input-bordered placeholder:text-sm rounded-lg`} />
                                                                                          </div>
                                                                                          <div className='flex flex-col mt-3'>
                                                                                                    <label htmlFor='password' className='text-white mb-1'>Password</label>
                                                                                                    <input type='text' name='password' defaultValue={account?.password} placeholder='Type password' className={`${theme ? 'bg-white text-black' : 'bg-[#222830] text-white'} input input-bordered placeholder:text-sm rounded-lg`} />
                                                                                          </div>
                                                                                          <div className='flex flex-col mt-3'>
                                                                                                    <label htmlFor='sites' className='text-white mb-1'>Sites</label>
                                                                                                    <input type='text' name='sites' defaultValue={account?.url} placeholder='Type sites' className={`${theme ? 'bg-white text-black' : 'bg-[#222830] text-white'} input input-bordered placeholder:text-sm rounded-lg`} />
                                                                                          </div>
                                                                                          <div className='flex flex-col mt-3'>
                                                                                                    <label htmlFor='notes' className='text-white mb-1'>Notes</label>
                                                                                                    <textarea typeof='text' style={{ resize: 'none', minHeight: '150px' }} name='notes' defaultValue={account?.notes} placeholder='Type notes' className={`${theme ? 'bg-white text-black' : 'bg-[#222830] text-white'} input input-bordered placeholder:text-sm pt-3 rounded-lg`} />
                                                                                          </div>
                                                                                          <div className='flex justify-end gap-2 mt-5'>
                                                                                                    <button onClick={() => setIsEditAccount(false)} className={`${theme ? 'bg-white text-black' : 'btn-neutral text-white'} shadow-lg btn btn-sm`}>
                                                                                                              <AiOutlineCloseCircle />
                                                                                                    </button>
                                                                                                    <button type='submit' className={`${theme ? 'bg-white text-black' : 'btn-neutral text-white'} shadow-lg btn btn-sm`}>
                                                                                                              <GoIssueClosed />
                                                                                                    </button>
                                                                                          </div>
                                                                                </form>
                                                                      </>
                                                            )
                                                  }
                                        </div>
                              </div>
                    </div>
          )
}