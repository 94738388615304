import React from 'react'
import { Navigate } from 'react-router-dom'
import useStore from '../store/store'

type Props = {
          Component: React.FC
}

export default function PublicOnlyRoute({ Component }: Props) {
          const { isLoggedIn } = useStore();
          return isLoggedIn ? <Navigate to='/dashboard' replace /> : <Component />
}
