export default function DateFormate(value: number) {
          if (!value) return "";
          const date = new Date(value);
          const monthNames = [
                    "January",
                    "February",
                    "March",
                    "April",
                    "May",
                    "June",
                    "July",
                    "August",
                    "September",
                    "October",
                    "November",
                    "December",
          ];

          const dayNames = [
                    "Sunday",
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday",
                    "Saturday",
          ];

          let hrs = date.getHours() as any;
          let amPm = hrs >= 12 ? "PM" : "AM";
          hrs = hrs % 12;
          hrs = hrs ? hrs : 12;
          hrs = hrs < 10 ? "0" + hrs : hrs;

          let min = date.getMinutes() as any;
          min = min < 10 ? "0" + min : min;

          let sec = date.getSeconds() as any;
          sec = sec < 10 ? "0" + sec : sec;

          let day = date.getDate();
          const month = monthNames[date.getMonth()];
          const year = date.getFullYear();

          const dayName = dayNames[date.getDay()];

          return `${hrs}:${min}:${sec} ${amPm}, ${dayName} ${day} ${month} ${year}`;
}
