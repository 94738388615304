import React, { useContext, useState } from 'react'
import useScrollToTop from '../../../hooks/useScrollToTop';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { BASE_API } from '../../../config';
import toast from 'react-hot-toast';
import AppLoader from '../../../shared/AppLoader';
import ScrollButton from '../../../shared/ScrollButton';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import DateFormate from '../../../utils/DateFormate';
import CategoryIcon from '../ManageAccounts/CategoryIcon';
import { CategoryItem } from '../../../utils/CategoryItem';
import { BiEditAlt, BiSolidDownload, BiTrashAlt } from 'react-icons/bi';
import Swal from 'sweetalert2';
import { GoIssueClosed } from 'react-icons/go';
import { InitializeContext } from '../../../App';

const initForm = {
          category: '',
          title: '',
          body: ''
}

export default function ManageNotes() {
          useScrollToTop();
          const { theme } = useContext(InitializeContext);
          const [isFormOpen, setIsFormOpen] = useState<boolean>(false);
          const [isEditNote, setIsEditNote] = useState<any>({ status: false, id: '' });
          const [form, setForm] = useState(initForm);

          const handleChange = (e: any) => {
                    setForm((oldForm) => ({
                              ...oldForm,
                              [e.target.name]: e.target.value
                    }));
          }

          const { data, refetch, isLoading } = useQuery(["all-notes"], async () => {
                    const res = await axios.get(`${BASE_API}/notes/all-notes`);
                    return res?.data;
          });

          const notes = data?.data;

          const handleAddNote = async (e: React.FormEvent<HTMLFormElement>) => {
                    e.preventDefault();
                    if (!form?.title || !form?.body || !form?.category) {
                              toast.error('Please enter all fields!');
                              return;
                    } else if (form?.title?.length > 100) {
                              toast.error('Title must be less than 100 characters');
                              return;
                    } else if (form?.body?.length > 1000) {
                              toast.error('Body must be less than 1000 characters');
                              return;
                    } else {
                              const data = {
                                        category: form?.category,
                                        title: form?.title,
                                        body: form?.body
                              }
                              const res = await axios.post(`${BASE_API}/notes/note`, data);
                              if (res?.data?.status === 201) {
                                        toast.success(res?.data?.message);
                                        setIsFormOpen(false);
                                        setForm(initForm);
                                        refetch();
                              } else {
                                        toast.error(res?.data?.message);
                              }
                    }
          }

          const handleDownload = (id: string) => {
                    const title = notes?.find((item: any) => item?._id === id)?.title;
                    const text = notes?.find((item: any) => item?._id === id)?.body;
                    if (text === '') {
                              return toast.error('Nothing to download!', {
                                        position: 'top-right',
                              })
                    } else {
                              Swal.fire({
                                        title: 'Are you sure?',
                                        text: "You want to download this note!",
                                        icon: 'warning',
                                        background: theme ? '#ffffff' : '#222830',
                                        color: theme ? '#000000' : '#ffffff',
                                        showCancelButton: true,
                                        confirmButtonColor: '#3085d6',
                                        cancelButtonColor: '#d33',
                                        confirmButtonText: 'Yes, sure!'
                              }).then((result: any) => {
                                        if (result.isConfirmed) {
                                                  const blob = new Blob([text], { type: 'text/plain' });
                                                  const url = URL.createObjectURL(blob);
                                                  const link = document.createElement('a');
                                                  link.href = url;
                                                  link.download = `Notes-${title.slice(0, 10)}.txt`;
                                                  link.click();
                                                  URL.revokeObjectURL(url);
                                                  link.remove();
                                        }
                              }
                              )
                    }
          };

          const handleUpdate = async (e: React.FormEvent<HTMLFormElement>, id: string) => {
                    e.preventDefault();
                    const formData = new FormData(e.currentTarget);
                    const title = formData.get('title');
                    const body = formData.get('body');

                    if (!title || !body) {
                              return toast.error('Please fill all the fields');
                    } else if (title.length > 100) {
                              return toast.error('Title must be less than 100 characters');
                    } else if (body.length > 1000) {
                              return toast.error('Body must be less than 1000 characters');
                    } else {
                              const res = await axios.patch(`${BASE_API}/notes/note?id=${id}`, {
                                        title,
                                        body
                              });

                              if (res?.data?.status === 200) {
                                        setIsEditNote({ status: false, id: '' });
                                        Swal.fire({
                                                  title: 'Updated!',
                                                  text: res?.data?.message,
                                                  icon: 'success',
                                                  background: theme ? '#ffffff' : '#222830',
                                                  color: theme ? '#000000' : '#ffffff',
                                                  showConfirmButton: false,
                                                  timer: 2000,
                                        });
                                        refetch();
                              } else {
                                        toast.error(res?.data?.message);
                              }
                    }
          }

          const handleDelete = async (id: string) => {
                    Swal.fire({
                              title: 'Are you sure?',
                              text: "You won't be able to revert this!",
                              icon: 'warning',
                              background: theme ? '#ffffff' : '#222830',
                              color: theme ? '#000000' : '#ffffff',
                              showCancelButton: true,
                              confirmButtonText: 'Yes, sure!',
                              confirmButtonColor: '#3085d6',
                              cancelButtonColor: '#d33',
                    }).then(async (result: any) => {
                              if (result.isConfirmed) {
                                        const res = await axios.delete(`${BASE_API}/notes/note?id=${id}`);
                                        if (res?.data?.status === 200) {
                                                  toast.success(res?.data?.message);
                                                  refetch();
                                        } else {
                                                  Swal.fire({
                                                            title: 'Error!',
                                                            text: res?.data?.message,
                                                            icon: 'error',
                                                            background: theme ? '#ffffff' : '#222830',
                                                            color: theme ? '#000000' : '#ffffff',
                                                            confirmButtonText: 'Ok, got it!'
                                                  });
                                        }
                              }
                    }
                    )
          }

          if (isLoading) {
                    return <AppLoader />
          }

          return (
                    <div className='px-2 py-5'>
                              <h1 className={`text-lg md:text-2xl ${theme ? 'text-black' : 'text-white'} font-semibold`}>
                                        All Notes ({notes?.length})
                              </h1>
                              <div className='flex justify-center items-center md:justify-end gap-3 mt-5 md:mt-0 mb-10'>
                                        <button hidden={isFormOpen} onClick={() => setIsFormOpen(true)} className={`duration-300 ${theme ? 'bg-white text-black hover:text-white' : 'bg-neutral text-white'} shadow-lg hover:bg-gray-600 transition duration-500 px-3 py-3 rounded-lg`}>Add New Notes</button>
                              </div>
                              {isFormOpen && (
                                        <div className={`${theme ? 'bg-white' : 'bg-[#222830]'} rounded-2xl shadow-lg p-5 w-full md:w-1/2 lg:w-1/3 mx-auto ${isFormOpen && '-mt-10 md:mt-0'}`}>
                                                  <div className='flex justify-between items-center'>
                                                            <h1 className={`text-lg font-semibold ${theme ? 'text-black' : 'text-white'}`}>Add New Notes</h1>
                                                            <button onClick={() => setIsFormOpen(false)} className={`${theme ? 'text-black bg-white' : 'text-white btn-neutral'} shadow-lg btn btn-xs`}>
                                                                      <AiOutlineCloseCircle />
                                                            </button>
                                                  </div>
                                                  <div className='mt-5'>
                                                            <form onSubmit={handleAddNote}>
                                                                      <div className='flex flex-col'>
                                                                                <label htmlFor='category' className={`${theme ? 'text-black' : 'text-white'} mb-1`}>Category</label>
                                                                                <select name='category' onChange={handleChange} className={`${theme ? 'bg-white' : 'bg-[#222830]'} capitalize select select-bordered rounded-lg placeholder:text-sm text-white`}>
                                                                                          <option disabled>Select your category</option>
                                                                                          {CategoryItem?.map((category: any) => (
                                                                                                    <option key={category?._id} value={category?._id}>{category?.name}</option>
                                                                                          ))}
                                                                                </select>
                                                                      </div>
                                                                      <div className='flex flex-col mt-4'>
                                                                                <label htmlFor='title' className={`${theme ? 'text-black' : 'text-white'} mb-1`}>Title</label>
                                                                                <input type='text' name='title' autoComplete='off' onChange={handleChange} placeholder='Type Title' className={`${theme ? 'bg-white' : 'bg-[#222830]'} input input-bordered rounded-lg placeholder:text-sm text-white`} />
                                                                      </div>
                                                                      <div className='flex flex-col mt-4'>
                                                                                <label htmlFor='body' className={`${theme ? 'text-black' : 'text-white'} mb-1`}>Description</label>
                                                                                <textarea typeof='text' style={{ resize: 'none', height: '200px' }} name='body' onChange={handleChange} autoComplete='off' placeholder='Type Description' className={`${theme ? 'bg-white' : 'bg-[#222830]'} pt-3 input input-bordered rounded-lg placeholder:text-sm text-white`} />
                                                                      </div>
                                                                      <div className='flex justify-end mt-5'>
                                                                                <button className={`${theme ? 'text-black bg-white' : 'text-white btn-neutral'} shadow-lg btn btn-sm md:btn-md hover:bg-gray-600 transition duration-500`}
                                                                                >Add Note</button>
                                                                      </div>
                                                            </form>
                                                  </div>
                                        </div>
                              )}
                              <div className='mt-5 mb-10 md:mb-0'>
                                        {
                                                  notes?.length > 0 ? (
                                                            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5'>
                                                                      {
                                                                                notes?.map((note: any) => (
                                                                                          <div key={note?._id}>
                                                                                                    {
                                                                                                              isEditNote?.id === note?._id ? (
                                                                                                                        <form onSubmit={(e) => handleUpdate(e, note?._id)} className='flex flex-col gap-1'>
                                                                                                                                  <div className={`${theme ? 'bg-white' : 'bg-[#222830]'} rounded-2xl rounded-bl-none rounded-br-none shadow-lg px-3 py-4`}>
                                                                                                                                            <h1 className={`text-sm font-semibold ${theme ? 'text-black' : 'text-white'}`}>
                                                                                                                                                      <input type="text" name="title" defaultValue={note?.title} className='bg-transparent border-none focus:outline-none w-full' />
                                                                                                                                            </h1>
                                                                                                                                  </div>
                                                                                                                                  <div className={`${theme ? 'bg-white' : 'bg-[#222830]'} rounded-none shadow-lg px-3 pt-3 h-60`}>
                                                                                                                                            <p className={`text-sm ${theme ? 'text-black' : 'text-white'} h-[215px] overflow-y-hidden`}>
                                                                                                                                                      <textarea typeof="text" name="body" defaultValue={note?.body} className='bg-transparent border-none h-60 focus:outline-none w-full' />
                                                                                                                                            </p>
                                                                                                                                  </div>
                                                                                                                                  <div className={`${theme ? 'bg-white' : 'bg-[#222830]'} flex justify-end gap-3 shadow-lg px-3 py-2 pb-3.5 rounded-2xl rounded-tl-none rounded-tr-none`}>
                                                                                                                                            <button onClick={() => setIsEditNote({ status: false, id: '' })} className={`${theme ? 'bg-white shadow-lg' : 'btn-neutral'} btn btn-xs`}>
                                                                                                                                                      <AiOutlineCloseCircle className={`${theme ? 'text-black' : 'text-warning'}`} />
                                                                                                                                            </button>
                                                                                                                                            <button type='submit' className={`${theme ? 'bg-white shadow-lg' : 'btn-neutral'} btn btn-xs`}>
                                                                                                                                                      <GoIssueClosed className={`${theme ? 'text-black' : 'text-warning'}`} />
                                                                                                                                            </button>
                                                                                                                                  </div>
                                                                                                                        </form>
                                                                                                              ) : (
                                                                                                                        <div className='flex flex-col gap-1'>
                                                                                                                                  <div className={`${theme ? 'bg-white' : 'bg-[#222830]'} rounded-2xl flex justify-between rounded-bl-none rounded-br-none shadow-lg px-3 py-4`}>
                                                                                                                                            <h1 className={`text-sm font-semibold ${theme ? 'text-black' : 'text-white'} cursor-not-allowed select-none`}>
                                                                                                                                                      {note?.title ? note?.title?.length > 35 ? note?.title?.slice(0, 35) + '...' : note?.title : 'No Title'}
                                                                                                                                            </h1>
                                                                                                                                            <h2 className='flex justify-center items-center cursor-not-allowed select-none'>
                                                                                                                                                      {CategoryIcon(note?.category, 20)}
                                                                                                                                            </h2>
                                                                                                                                  </div>
                                                                                                                                  <div className={`${theme ? 'bg-white' : 'bg-[#222830]'} rounded-none shadow-lg px-3 pt-3 h-60 relative`}>
                                                                                                                                            <p className={`text-sm ${theme ? 'text-black' : 'text-white'} h-[215px] overflow-auto cursor-not-allowed select-none pr-8`}>
                                                                                                                                                      {note?.body}
                                                                                                                                            </p>
                                                                                                                                            <div className={`grid grid-cols-1 gap-2 absolute top-2 right-2 ${theme ? 'bg-[#ECF0F3]' : 'bg-neutral'} rounded-md ${isFormOpen && 'hidden'}`}>
                                                                                                                                                      <button onClick={() => handleDownload(note?._id)} className='px-2 py-1 text-sm'>
                                                                                                                                                                <BiSolidDownload className={`${theme ? 'text-black' : 'text-warning'}`} />
                                                                                                                                                      </button>
                                                                                                                                                      <button onClick={() => setIsEditNote({ status: true, id: note?._id })} className='px-2 py-1 text-sm'>
                                                                                                                                                                <BiEditAlt className={`${theme ? 'text-black' : 'text-warning'}`} />
                                                                                                                                                      </button>
                                                                                                                                                      <button onClick={() => handleDelete(note?._id)} className='px-2 py-1 text-sm'>
                                                                                                                                                                <BiTrashAlt className={`${theme ? 'text-black' : 'text-warning'}`} />
                                                                                                                                                      </button>
                                                                                                                                            </div>
                                                                                                                                  </div>
                                                                                                                                  <div className={`${theme ? 'bg-white' : 'bg-[#222830]'} flex justify-between shadow-lg px-3 py-2 rounded-2xl rounded-tl-none rounded-tr-none`}>
                                                                                                                                            <div>
                                                                                                                                                      <p className={`text-[10px] font-semibold ${theme ? 'text-black' : 'text-white'}`}>
                                                                                                                                                                Created At: <span className={`${theme ? 'text-black' : 'text-warning'}`}>{DateFormate(note?.createdAt)}</span>
                                                                                                                                                      </p>
                                                                                                                                                      {note?.updatedAt ? (
                                                                                                                                                                <p className={`text-[10px] font-semibold ${theme ? 'text-black' : 'text-white'}`}>
                                                                                                                                                                          Updated At: <span className={`${theme ? 'text-black' : 'text-warning'}`}>{DateFormate(note?.updatedAt)}</span>
                                                                                                                                                                </p>
                                                                                                                                                      ) : (
                                                                                                                                                                <p className={`text-[10px] ${theme ? 'text-black' : 'text-white'}`}>
                                                                                                                                                                          Updated At: <span className={`${theme ? 'text-black' : 'text-warning'}`}>{DateFormate(note?.createdAt)}</span>
                                                                                                                                                                </p>
                                                                                                                                                      )
                                                                                                                                                      }
                                                                                                                                            </div>
                                                                                                                                            <div>
                                                                                                                                                      <p className={`text-[10px] font-semibold ${theme ? 'text-black' : 'text-white'}`}>
                                                                                                                                                                Characters: <span className={`${theme ? 'text-black' : 'text-warning'}`}>{note?.bodyCharacter}</span>
                                                                                                                                                      </p>
                                                                                                                                                      <p className={`text-[10px] font-semibold ${theme ? 'text-black' : 'text-white'}`}>
                                                                                                                                                                Words: <span className={`${theme ? 'text-black' : 'text-warning'}`}>{note?.bodyWord}</span>
                                                                                                                                                      </p>
                                                                                                                                            </div>
                                                                                                                                  </div>
                                                                                                                        </div>
                                                                                                              )
                                                                                                    }
                                                                                          </div>
                                                                                ))
                                                                      }
                                                            </div>
                                                  ) : (
                                                            <div className={`${theme ? 'bg-[#ECF0F3]' : 'bg-[#222830]'} rounded-2xl shadow-lg p-5 mt-10 w-full md:w-1/2 lg:w-1/3 mx-auto`}>
                                                                      <h1 className='text-lg font-semibold text-center text-white'>No Notes Found!</h1>
                                                            </div>
                                                  )
                                        }
                              </div>
                              <ScrollButton />
                    </div>
          )
}
