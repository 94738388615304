import React, { useState, useEffect, useContext } from 'react'
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { BASE_API } from '../../../config';
import { useNavigate } from 'react-router-dom';
import AppLoader from '../../../shared/AppLoader';
import { toast } from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import { BiTrashAlt, BiEditAlt } from 'react-icons/bi';
import { BsSearchHeart } from 'react-icons/bs';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { GoIssueClosed } from 'react-icons/go';
import DateFormate from '../../../utils/DateFormate';
import CategoryIcon from './CategoryIcon';
import useScrollToTop from '../../../hooks/useScrollToTop';
import ScrollButton from '../../../shared/ScrollButton';
import { InitializeContext } from '../../../App';

type EditCategory = {
  status: boolean;
  id: string;
}

export default function ManageAccounts() {
  useScrollToTop();
  const { theme } = useContext(InitializeContext);
  const navigate = useNavigate();
  const [isSearchFormOpen, setIsSearchFormOpen] = useState<boolean>(false);
  const [isFormOpen, setIsFormOpen] = useState<boolean>(false);
  const [isEditCategory, setIsEditCategory] = useState<EditCategory>({ status: false, id: '' });
  const [inputCategory, setInputCategory] = useState<string>('');
  const [searchedData, setSearchedData] = useState<any[]>([]);
  const { setValue } = useForm();

  useEffect(() => {
    setValue("category", isEditCategory?.id);
  }, [isEditCategory?.id, setValue]);

  const { data, refetch, isLoading } = useQuery(["categories"], async () => {
    const res = await axios.get(`${BASE_API}/accounts/categories-only`);
    return res?.data;
  });

  const categories = data?.data;

  useEffect(() => {
    if (!searchedData?.length) {
      setSearchedData(categories);
      refetch();
    }
  }, [searchedData, categories, refetch]);

  const handleSearchCategory = async (e: any) => {
    e.preventDefault();
    const value = e.target.value.toLowerCase();
    const filter = categories?.filter((item: any) => {
      return (
        item.category.toLowerCase().includes(value)
      );
    });
    setSearchedData(filter);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const category = formData.get('category') as string;
    if (!category) {
      toast.error('Please enter category');
      return;
    }
    const res = await axios.post(`${BASE_API}/accounts/category`, { category });
    if (res?.data?.status === 201) {
      refetch();
      toast.success(res?.data?.message);
      setIsFormOpen(false);
      formData.delete('category');
    } else {
      toast.error(res?.data?.message);
    }
  }

  const handleDelete = async (id: string) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to delete this category?",
      icon: 'warning',
      background: theme ? '#ffffff' : '#222830',
      color: theme ? '#000000' : '#ffffff',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33'
    }).then(async (result: any) => {
      if (result.isConfirmed) {
        const res = await axios.delete(`${BASE_API}/accounts/category?id=${id}`);
        if (res?.data?.status === 200) {
          refetch();
          Swal.fire({
            title: 'Deleted!',
            text: res?.data?.message,
            icon: 'success',
            background: theme ? '#ffffff' : '#222830',
            color: theme ? '#000000' : '#ffffff',
            showConfirmButton: false,
            timer: 1500
          });
        } else {
          Swal.fire({
            title: 'Warning!',
            text: res?.data?.message,
            icon: 'warning',
            background: theme ? '#ffffff' : '#222830',
            color: theme ? '#000000' : '#ffffff',
            confirmButtonText: 'Ok, got it!'
          });
        }
      }
    }
    )
  }

  const handleEditCategory = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const category = formData.get('category') as string;
    if (!category || category === '') {
      toast.error('Please enter category');
      return;
    }
    const res = await axios.put(`${BASE_API}/accounts/category?id=${isEditCategory?.id}`, { category: inputCategory });
    if (res?.data?.status === 200) {
      refetch();
      Swal.fire({
        title: 'Success!',
        text: res?.data?.message,
        icon: 'success',
        background: theme ? '#ffffff' : '#222830',
        color: theme ? '#000000' : '#ffffff',
        showConfirmButton: false,
        timer: 1500
      });
      setIsEditCategory({ status: false, id: '' });
      formData.delete('category');
    } else {
      toast.error(res?.data?.message);
    }
  }

  if (isLoading) {
    return <AppLoader />
  }

  return (
    <div className='py-5'>
      <h1 className={`text-lg md:text-2xl ${theme ? 'text-black' : 'text-white'} font-semibold`}>
        All Categories ({categories?.length})
      </h1>
      <div className='flex justify-center items-center md:justify-end gap-3 mt-5 md:mt-0 mb-10'>
        <div hidden={isSearchFormOpen || isFormOpen} onClick={() => setIsSearchFormOpen(true)} className={`${theme ? 'bg-white text-black' : 'bg-neutral hover:bg-gray-600 text-white'} shadow-lg cursor-pointer transition duration-500 px-4 py-3 rounded-lg`}>
          <BsSearchHeart size={22} />
        </div>
        <button hidden={isFormOpen || isSearchFormOpen} onClick={() => setIsFormOpen(true)} className={`${theme ? 'bg-white text-black' : 'bg-neutral hover:bg-gray-600 text-white'} shadow-lg transition duration-500 px-4 py-3 rounded-lg`}>Add New Category</button>
      </div>
      {
        isSearchFormOpen && (
          <div className={`${theme ? 'bg-white' : 'bg-[#222830]'} rounded-2xl shadow-lg p-5 w-full md:w-1/2 lg:w-1/3 mx-auto ${isSearchFormOpen && '-mt-10 md:mt-0'}`}>
            <div className='flex justify-between items-center'>
              <h1 className={`text-lg font-semibold ${theme ? 'text-black' : 'text-white'}`}>Search Category</h1>
              <button onClick={() => setIsSearchFormOpen(false)} className={`${theme ? 'bg-white text-black' : 'btn-neutral text-white'} shadow-lg btn btn-xs`}>
                <AiOutlineCloseCircle />
              </button>
            </div>
            <div className='mt-3'>
              <div className='flex flex-col'>
                <label htmlFor='category' className={`${theme ? 'text-black' : 'text-white'} mb-1`}>Category</label>
                <input type='text' name='category' autoComplete='off' onChange={handleSearchCategory} placeholder='Type Category' className={`${theme ? 'bg-white text-black' : 'bg-[#222830] text-white'} input input-bordered rounded-lg placeholder:text-sm`} />
              </div>
            </div>
          </div>
        )
      }
      {isFormOpen && (
        <div className={`${theme ? 'bg-white' : 'bg-[#222830]'} rounded-2xl shadow-lg p-5 w-full md:w-1/2 lg:w-1/3 mx-auto ${isFormOpen && '-mt-10 md:mt-0'}`}>
          <div className='flex justify-between items-center'>
            <h1 className={`text-lg font-semibold ${theme ? 'text-black' : 'text-white'}`}>Add New Category</h1>
            <button onClick={() => setIsFormOpen(false)} className={`${theme ? 'bg-white text-black' : 'btn-neutral text-white'} shadow-lg btn btn-xs`}>
              <AiOutlineCloseCircle />
            </button>
          </div>
          <div className='mt-5'>
            <form onSubmit={handleSubmit}>
              <div className='flex flex-col'>
                <label htmlFor='category' className={`${theme ? 'text-black' : 'text-white'} mb-1`}>Category</label>
                <input type='text' name='category' autoComplete='off' placeholder='Type Category' className={`${theme ? 'bg-white text-black' : 'bg-[#222830] text-white'} input input-bordered rounded-lg placeholder:text-sm `} />
              </div>
              <div className='flex justify-end mt-5'>
                <button className={`${theme ? 'bg-white text-black' : 'btn-neutral text-white'} shadow-lg btn btn-sm`}
                >Add</button>
              </div>
            </form>
          </div>
        </div>
      )}
      <div className='mt-5'>
        {
          searchedData?.length > 0 ? (
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5'>
              {
                searchedData.map((category: any) => (
                  <div className={`${theme ? 'bg-white' : 'bg-[#222830]'} rounded-2xl shadow-lg p-5`} key={category._id}>
                    <div className='flex flex-col items-center justify-between gap-5 relative'>
                      <div>
                        <h2 className='flex justify-center mb-5 cursor-pointer' onClick={() => navigate(`/dashboard/manageAccounts/${category?._id}`)}>
                          {CategoryIcon(category?.category, 50)}
                        </h2>
                        {
                          isEditCategory?.status && isEditCategory?.id === category._id ? (
                            <form onSubmit={handleEditCategory} className='flex justify-center items-center gap-3'>
                              <input type='text' onChange={(e) => setInputCategory(e.target.value)}
                                defaultValue={category?.category} name='category' placeholder='Type Category' className={`${theme ? 'bg-white text-black' : 'bg-[#222830] text-white'} input input-bordered input-sm rounded-lg placeholder:text-sm -mt-1`} />
                              <div className='flex justify-center gap-1'>
                                <button onClick={() => setIsEditCategory({ status: false, id: '' })} className={`shadow-md btn btn-xs ${theme ? 'bg-white text-black' : 'btn-neutral text-white'}`}>
                                  <AiOutlineCloseCircle />
                                </button>
                                <button type='submit' className={`shadow-md btn btn-xs ${theme ? 'bg-white text-black' : 'btn-neutral text-white'}`}>
                                  <GoIssueClosed />
                                </button>
                              </div>
                            </form>
                          ) : (
                            <h1 className={`text-lg font-semibold text-center capitalize ${theme ? 'text-black' : 'text-white'} cursor-pointer`} onClick={() => navigate(`/dashboard/manageAccounts/${category?._id}`)}>{category?.category}</h1>
                          )
                        }
                        {
                          category?.accountsLength > 0 ? (
                            <p className='text-sm font-semibold text-success text-center mt-2'>
                              {category?.accountsLength} {category?.accountsLength > 1 ? 'accounts' : 'account'} Found
                            </p>
                          ) : (
                            <p className='text-sm font-semibold text-error text-center mt-2'>
                              No Account Found
                            </p>
                          )
                        }
                        <p className={`text-xs ${theme ? 'text-black' : 'text-white'} text-center mt-2`}>
                          Created At: <br /> {DateFormate(category?.createdAt)}
                        </p>
                        {category?.updatedAt ? (
                          <p className={`text-xs ${theme ? 'text-black' : 'text-white'} text-center mt-3`}>
                            Updated At: <br /> {DateFormate(category?.updatedAt)}
                          </p>
                        ) : (
                          <p className={`text-xs ${theme ? 'text-black' : 'text-white'} text-center mt-3`}>
                            Updated At: <br /> {DateFormate(category?.createdAt)}
                          </p>
                        )
                        }
                      </div>
                      <div className={`${isEditCategory.id === category._id && 'hidden'} flex items-center absolute top-0 right-0`}>
                        <button onClick={() => setIsEditCategory({ status: true, id: category._id })} className={`btn btn-xs ${theme ? 'bg-white text-black shadow-md' : 'btn-neutral text-white'} mr-2`}>
                          <BiEditAlt />
                        </button>
                        <button onClick={() => handleDelete(category._id)} className={`btn btn-xs ${theme ? 'bg-white text-black shadow-md' : 'btn-neutral text-white'}`}>
                          <BiTrashAlt />
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
          ) : (
            <div className={`${theme ? 'bg-white' : 'bg-[#222830]'} rounded-2xl shadow-lg p-5 mt-10 w-full md:w-1/2 lg:w-1/3 mx-auto`}>
              <h1 className={`text-lg font-semibold text-center ${theme ? 'text-black' : 'text-white'}`}>No Categories Found!</h1>
            </div>
          )
        }
      </div>
      <ScrollButton />
    </div>
  )
}
