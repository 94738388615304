import { BsFacebook, BsTwitter, BsInstagram, BsDiscord, BsTiktok, BsWhatsapp, BsVimeo, BsWordpress, BsSteam, BsTrello, BsTelegram, BsStackOverflow, BsSnapchat, BsSpotify, BsDribbble, BsLinkedin, BsMicrosoft, BsGithub, BsGoogle, BsApple, BsPaypal, BsMeta } from 'react-icons/bs';
import { BiLogoAmazon, BiLogoBlogger, BiLogoReddit, BiLogoSkype, BiLogoGmail, BiLogoGitlab, BiLogoZoom, BiLogoUpwork, BiLogoHeroku, BiLogoMongodb, BiLogoPinterest } from 'react-icons/bi';
import { FaFigma, FaImdb, FaStripe, FaNpm } from 'react-icons/fa';
import { SiNamecheap, SiPayoneer, SiVercel, SiNetlify, SiJetbrains } from 'react-icons/si';
import { DiYahoo } from 'react-icons/di';
import { MdManageAccounts } from "react-icons/md";
import { InitializeContext } from '../../../App';
import { useContext } from 'react';

export default function CategoryIcon(icon: any, size: number) {
          const { theme } = useContext(InitializeContext);
          if (!icon) return "";
          switch (icon) {
                    case "facebook":
                              return <BsFacebook size={size} className={`${theme ? 'text-black' : 'text-white'}`} />;
                    case "twitter":
                              return <BsTwitter size={size} className={`${theme ? 'text-black' : 'text-white'}`} />;
                    case "instagram":
                              return <BsInstagram size={size} className={`${theme ? 'text-black' : 'text-white'}`} />;
                    case "linkedin":
                              return <BsLinkedin size={size} className={`${theme ? 'text-black' : 'text-white'}`} />;
                    case "microsoft":
                              return <BsMicrosoft size={size} className={`${theme ? 'text-black' : 'text-white'}`} />;
                    case "github":
                              return <BsGithub size={size} className={`${theme ? 'text-black' : 'text-white'}`} />;
                    case "google":
                              return <BsGoogle size={size} className={`${theme ? 'text-black' : 'text-white'}`} />;
                    case "apple":
                              return <BsApple size={size} className={`${theme ? 'text-black' : 'text-white'}`} />;
                    case "paypal":
                              return <BsPaypal size={size} className={`${theme ? 'text-black' : 'text-white'}`} />;
                    case "namecheap":
                              return <SiNamecheap size={size} className={`${theme ? 'text-black' : 'text-white'}`} />;
                    case "payoneer":
                              return <SiPayoneer size={size} className={`${theme ? 'text-black' : 'text-white'}`} />;
                    case "yahoo":
                              return <DiYahoo size={size} className={`${theme ? 'text-black' : 'text-white'}`} />;
                    case "vercel":
                              return <SiVercel size={size} className={`${theme ? 'text-black' : 'text-white'}`} />;
                    case "netlify":
                              return <SiNetlify size={size} className={`${theme ? 'text-black' : 'text-white'}`} />;
                    case "figma":
                              return <FaFigma size={size} className={`${theme ? 'text-black' : 'text-white'}`} />;
                    case "imdb":
                              return <FaImdb size={size} className={`${theme ? 'text-black' : 'text-white'}`} />;
                    case "stripe":
                              return <FaStripe size={size} className={`${theme ? 'text-black' : 'text-white'}`} />;
                    case "npm":
                              return <FaNpm size={size} className={`${theme ? 'text-black' : 'text-white'}`} />;
                    case "amazon":
                              return <BiLogoAmazon size={size} className={`${theme ? 'text-black' : 'text-white'}`} />;
                    case "blogger":
                              return <BiLogoBlogger size={size} className={`${theme ? 'text-black' : 'text-white'}`} />;
                    case "reddit":
                              return <BiLogoReddit size={size} className={`${theme ? 'text-black' : 'text-white'}`} />;
                    case "skype":
                              return <BiLogoSkype size={size} className={`${theme ? 'text-black' : 'text-white'}`} />;
                    case "gmail":
                              return <BiLogoGmail size={size} className={`${theme ? 'text-black' : 'text-white'}`} />;
                    case "gitlab":
                              return <BiLogoGitlab size={size} className={`${theme ? 'text-black' : 'text-white'}`} />;
                    case "zoom":
                              return <BiLogoZoom size={size} className={`${theme ? 'text-black' : 'text-white'}`} />;
                    case "upwork":
                              return <BiLogoUpwork size={size} className={`${theme ? 'text-black' : 'text-white'}`} />;
                    case "heroku":
                              return <BiLogoHeroku size={size} className={`${theme ? 'text-black' : 'text-white'}`} />;
                    case "mongodb":
                              return <BiLogoMongodb size={size} className={`${theme ? 'text-black' : 'text-white'}`} />;
                    case "pinterest":
                              return <BiLogoPinterest size={size} className={`${theme ? 'text-black' : 'text-white'}`} />;
                    case "discord":
                              return <BsDiscord size={size} className={`${theme ? 'text-black' : 'text-white'}`} />;
                    case "tiktok":
                              return <BsTiktok size={size} className={`${theme ? 'text-black' : 'text-white'}`} />;
                    case "whatsapp":
                              return <BsWhatsapp size={size} className={`${theme ? 'text-black' : 'text-white'}`} />;
                    case "vimeo":
                              return <BsVimeo size={size} className={`${theme ? 'text-black' : 'text-white'}`} />;
                    case "wordpress":
                              return <BsWordpress size={size} className={`${theme ? 'text-black' : 'text-white'}`} />;
                    case "steam":
                              return <BsSteam size={size} className={`${theme ? 'text-black' : 'text-white'}`} />;
                    case "trello":
                              return <BsTrello size={size} className={`${theme ? 'text-black' : 'text-white'}`} />;
                    case "telegram":
                              return <BsTelegram size={size} className={`${theme ? 'text-black' : 'text-white'}`} />;
                    case "stackoverflow":
                              return <BsStackOverflow size={size} className={`${theme ? 'text-black' : 'text-white'}`} />;
                    case "snapchat":
                              return <BsSnapchat size={size} className={`${theme ? 'text-black' : 'text-white'}`} />;
                    case "spotify":
                              return <BsSpotify size={size} className={`${theme ? 'text-black' : 'text-white'}`} />;
                    case "dribbble":
                              return <BsDribbble size={size} className={`${theme ? 'text-black' : 'text-white'}`} />;
                    case "metamask":
                              return <BsMeta size={size} className={`${theme ? 'text-black' : 'text-white'}`} />;
                    case "jetbrains":
                              return <SiJetbrains size={size} className={`${theme ? 'text-black' : 'text-white'}`} />;
                    default:
                              return <MdManageAccounts size={size} className={`${theme ? 'text-black' : 'text-white'}`} />;
          }
}
