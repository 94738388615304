import React, { useContext } from 'react'
import auth from '../../auth/firebase';
import Avatar from "../../assets/me.jpg";
import { InitializeContext } from '../../App';

export default function Index() {
  const { theme } = useContext(InitializeContext);

  return (
    <div className="flex justify-center items-center flex-col h-screen">
      <div className="avatar md:mb-10">
        <div className="w-48 mask mask-hexagon">
          <img src={auth?.currentUser?.photoURL as string || Avatar} alt="" />
        </div>
      </div>
      <h2 className={`text-xl md:text-2xl lg:text-3xl text-center lg:flex lg:gap-2 ${theme ? 'text-black' : 'text-white'}`}>
        Welcome To Dashboard,{" "}
        <span className={`block lg:flex font-semibold ${theme ? 'text-error' : 'text-warning'}`}>
          {auth?.currentUser?.email}
        </span>
      </h2>
    </div>
  );
};

