import { toast } from 'react-hot-toast';

export default function CopiedClipboard(value: string) {
          if (!value) {
                    return toast.error('Nothing to copy');
          } else {
                    toast.success('Copied to clipboard');
                    return navigator.clipboard.writeText(value);
          }
}
