import React, { createContext, useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { onAuthStateChanged } from 'firebase/auth';
import auth from './auth/firebase';
import AuthScreen from './pages/AuthScreen/AuthScreen';
import useStore from './store/store';
import PrivateOnlyRoute from './utils/PrivateOnlyRoute';
import Dashboard from './pages/Dashboard/Dashboard';
import PublicOnlyRoute from './utils/PublicOnlyRoute';
import Index from './pages/Dashboard/Index';
import Setting from './pages/Dashboard/Setting';
import NotFound from './shared/NotFound';
import AppLoader from './shared/AppLoader';
import ManageAccounts from './pages/Dashboard/ManageAccounts/ManageAccounts';
import Accounts from './pages/Dashboard/ManageAccounts/Accounts';
import SingleAccount from './pages/Dashboard/ManageAccounts/SingleAccount';
import ManageNotes from './pages/Dashboard/ManageNotes/ManageNotes';

export const InitializeContext = createContext(null as any);

function App() {
  const { loader, setLoginStatus } = useStore();
  const [theme, setTheme] = useState<boolean>(false);

  useEffect(() => {
    setTheme(JSON.parse(window.localStorage.getItem("accountManager") || false as any));
  }, []);

  useEffect(() => {
    const unSub = onAuthStateChanged(auth, (user) => {
      setLoginStatus(!!user)
    });
    return () => unSub();
  }, [setLoginStatus]);

  const toggleTheme = () => {
    setTheme(!theme);
    window.localStorage.setItem("accountManager", !theme as any);
  };

  return (
    <InitializeContext.Provider value={{ toggleTheme, theme }}>
      <div data-theme={theme ? 'light' : 'dark'} className={`${theme ? 'bg-[#ECF0F3]' : 'bg-[#222830]'}`}>
        {
          loader ?
            <AppLoader />
            :
            (
              <>
                <Routes>
                  <Route path="/" element={<PublicOnlyRoute Component={AuthScreen} />} />
                  <Route path="/dashboard" element={<PrivateOnlyRoute Component={Dashboard} />}>
                    <Route index element={<Index />} />
                    <Route path="/dashboard/manageAccounts" element={<ManageAccounts />} />
                    <Route path="/dashboard/manageAccounts/:categoryID" element={<Accounts />} />
                    <Route path="/dashboard/manageAccounts/:categoryID/:accountID" element={<SingleAccount />} />
                    <Route path="/dashboard/manageNotes" element={<ManageNotes />} />
                    <Route path="/dashboard/setting" element={<Setting />} />
                  </Route>
                  <Route path="*" element={<NotFound />} />
                </Routes>
                <Toaster />
              </>
            )
        }
      </div>
    </InitializeContext.Provider>
  );
}

export default App;
