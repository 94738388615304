import React, { useContext } from 'react'
import { InitializeContext } from '../../App'

export default function Setting() {
  const { theme, toggleTheme } = useContext(InitializeContext);

  return (
    <div className='px-2 py-5'>
      <h1 className={`text-lg md:text-2xl ${theme ? 'text-black' : 'text-white'} font-semibold`}>
        Setting
      </h1>
      <div className='flex justify-between items-center mt-5'>
        <p className={`${theme ? 'text-black' : 'text-white'} text-sm md:text-base`}>Theme</p>
        <span onClick={toggleTheme} className='cursor-pointer'>
          {
            theme ? (
              <i className='bx bx-moon text-xl'></i>
            ) : (
              <i className='bx bx-sun text-xl'></i>
            )
          }
        </span>
      </div>
    </div>
  )
}
