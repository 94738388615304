export const CategoryItem = [
          {
                    id: 1,
                    name: "facebook",
          },
          {
                    id: 2,
                    name: "twitter",
          },
          {
                    id: 3,
                    name: "google",
          },
          {
                    id: 4,
                    name: "telegram",
          },
          {
                    id: 5,
                    name: "instagram",
          },
          {
                    id: 6,
                    name: "linkedin",
          },
          {
                    id: 7,
                    name: "youtube",
          },
          {
                    id: 8,
                    name: "tiktok",
          },
          {
                    id: 9,
                    name: "whatsapp",
          },
          {
                    id: 10,
                    name: "github",
          },
          {
                    id: 10,
                    name: "gmail",
          },
          {
                    id: 11,
                    name: "outlook",
          },
          {
                    id: 12,
                    name: "apple",
          },
          {
                    id: 13,
                    name: "stripe",
          },
          {
                    id: 14,
                    name: "npm",
          },
          {
                    id: 15,
                    name: "jetbrains"
          },
          {
                    id: 16,
                    name: "mega"
          },
          {
                    id: 17,
                    name: "metamask"
          },
          {
                    id: 18,
                    name: "namecheap"
          }
];