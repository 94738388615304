import React, { useContext } from 'react'
import { NavLink, Outlet, Link } from 'react-router-dom'
import { useAuthState } from 'react-firebase-hooks/auth';
import auth from '../../auth/firebase';
import AppLoader from '../../shared/AppLoader';
import { toast } from 'react-hot-toast';
import { signOut } from 'firebase/auth';
import { MdSpaceDashboard } from "react-icons/md";
import LOGO from "../../assets/logo.png";
import Avatar from "../../assets/me.jpg";
import { MdManageAccounts } from "react-icons/md";
import Swal from "sweetalert2";
import { PiNotebookBold } from 'react-icons/pi';
import { InitializeContext } from '../../App';

export default function Dashboard() {
  const { theme, toggleTheme } = useContext(InitializeContext);
  const [user, isLoading] = useAuthState(auth);

  const handleLogOut = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will be signed out from this account.",
      icon: "warning",
      background: theme ? '#ffffff' : '#222830',
      color: theme ? '#000000' : '#ffffff',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: "Yes, sign out!",
    }).then((result: any) => {
      if (result.isConfirmed) {
        signOut(auth).then(() => {
          toast.success(`Thank you, ${user?.email} to stay with us!`, {
            position: "top-center",
          });
        }).catch((err) => {
          toast(err.message, {
            icon: '👎',
          })
        })
      }
    })
  };

  if (isLoading) {
    return <AppLoader />;
  }

  return (
    <div className="drawer lg:drawer-open">
      <input id="dashboard-sidebar" type="checkbox" className="drawer-toggle" />
      <div className="drawer-content p-3">
        <div className={`header ${theme ? 'bg-[#ECF0F3]' : 'bg-[#222830]'} z-50 sticky top-3 flex justify-between items-center shadow-lg p-4 rounded-xl`}>
          <label
            htmlFor="dashboard-sidebar"
            className={`btn btn-outline duration-500 drawer-button group lg:hidden ${theme ? 'text-black border-black' : 'text-white border-white hover:bg-gray-600 hover:border-gray-600'}`}
          >
            <MdSpaceDashboard className={`text-2xl duration-500 ${theme ? 'text-black group-hover:text-white' : 'text-white'}`} />
          </label>
          <div className={`flex items-center gap-1 ${theme ? 'text-black' : 'text-white'}`}>
            <h1 className="text-lg md:text-2xl font-semibold hidden md:flex">
              Welcome to
            </h1>
            <Link
              to="/dashboard"
              className={`text-lg md:text-2xl font-semibold ${theme ? 'md:text-error' : 'md:text-warning'}`}
            >
              Account Manager
            </Link>
            <h1 className="text-lg md:text-2xl font-semibold hidden md:flex">
              (Admin Panel)
            </h1>
          </div>
          <div onClick={toggleTheme} className='hidden md:flex absolute right-20 cursor-pointer'>
            {
              theme ? (
                <i className='bx bx-moon text-xl'></i>
              ) : (
                <i className='bx bx-sun text-xl'></i>
              )
            }
          </div>
          <div className="dropdown dropdown-end">
            <label
              tabIndex={0}
              className="btn btn-ghost btn-circle avatar online"
            >
              <div
                style={{ display: "grid" }}
                className={`w-10 h-10 place-items-center rounded-full ring ${theme ? 'ring-error' : 'ring-warning'} ring-offset-base-100 ring-offset-2`}
              >
                <img
                  src={auth?.currentUser?.photoURL as string || Avatar}
                  alt={auth?.currentUser?.displayName || ""}
                />
              </div>
            </label>
            <ul
              tabIndex={0}
              className={`mt-6 -mr-4 p-2 shadow-xl menu menu-compact dropdown-content rounded-box w-72 relative ${theme ? 'bg-white' : 'bg-[#222830]'}`}
            >
              <div onClick={toggleTheme} className='md:hidden absolute right-3 cursor-pointer'>
                {
                  theme ? (
                    <i className='bx bx-moon text-xl'></i>
                  ) : (
                    <i className='bx bx-sun text-xl'></i>
                  )
                }
              </div>
              <div className={`w-16 h-16 rounded-full flex items-center justify-center mx-auto my-4 ring ${theme ? 'ring-error' : 'ring-warning'} ring-offset-base-100 ring-offset-2`}>
                <img
                  src={auth?.currentUser?.photoURL as string || Avatar}
                  alt={auth?.currentUser?.email || ""}
                  className="rounded-full"
                />
              </div>
              <div className={`text-center mb-4 ${theme ? 'text-black' : 'text-white'}`}>
                <span className="font-semibold">Hello,</span>
                <span className="flex justify-center items-center gap-1 font-semibold">
                  <h2 className={`${theme ? 'text-error' : 'text-warning'}`}>
                    {auth?.currentUser?.displayName || auth?.currentUser?.email}
                  </h2>
                  <i className='bx bxs-hand' ></i>
                </span>
                <div className="flex flex-col items-center gap-1 pt-2">
                  <h1 className="font-semibold">
                    Welcome to,{" "}
                    <span className={`font-semibold ${theme ? 'text-error' : 'text-warning'}`}>
                      <Link to="/dashboard">Account Manager</Link>
                    </span>
                  </h1>
                  <h1 className="font-semibold">
                    (Admin Panel)
                  </h1>
                </div>
              </div>
              <hr className={`font-semibold ${theme ? 'text-black' : 'text-white'}`} />
              <li className={`py-2 ${theme ? 'text-black' : 'text-white'}`}>
                <button onClick={handleLogOut}>
                  <i className="bx bx-log-out font-semibold"></i>
                  Sign Out
                </button>
              </li>
            </ul>
          </div>
        </div>
        <Outlet />
      </div>
      <div className="drawer-side shadow-xl z-50">
        <label htmlFor="dashboard-sidebar" className="drawer-overlay"></label>
        <ul className={`menu p-4 overflow-y-auto h-full w-80 ${theme ? 'bg-[#ECF0F3]' : 'bg-[#222830]'} text-base-content`}>
          <div className={`flex flex-col items-center gap-3 text-2xl p-2 border-b ${theme ? 'border-black' : 'border-white'} pb-5`}>
            <Link
              to="/dashboard"
              className={`font-semibold text-lg md:text-xl text-center flex items-center flex-col gap-2 ${theme ? 'text-black' : 'text-white'}`}
            >
              <img src={LOGO} alt="Logo" className="w-16 rounded-lg" /> Account Manager
              <small className={`text-xs ${theme ? 'text-black' : 'text-white'} font-normal`}>
                A simple account manager app
              </small>
            </Link>
          </div>
          <li className="py-1 mt-3">
            <NavLink
              className={({ isActive }) =>
                isActive ? "text-white bg-gray-600 py-3 hover:bg-gray-600 hover:text-white" : `${theme ? 'text-black' : 'text-white'} py-3 hover:bg-gray-600 hover:text-white`
              }
              to="/dashboard"
            >
              <i className="bx bxs-dashboard text-xl"></i> Dashboard
            </NavLink>
          </li>
          <li className="py-1">
            <NavLink
              className={({ isActive }) =>
                isActive ? "text-white bg-gray-600 py-3 hover:bg-gray-600 hover:text-white" : `${theme ? 'text-black' : 'text-white'} py-3 hover:bg-gray-600 hover:text-white`
              }
              to="/dashboard/manageAccounts"
            >
              <MdManageAccounts size={24} /> Manage Accounts
            </NavLink>
          </li>
          <li className="py-1">
            <NavLink
              className={({ isActive }) =>
                isActive ? "text-white bg-gray-600 py-3 hover:bg-gray-600 hover:text-white" : `${theme ? 'text-black' : 'text-white'} py-3 hover:bg-gray-600 hover:text-white`
              }
              to="/dashboard/manageNotes"
            >
              <PiNotebookBold size={23} /> Manage Notes
            </NavLink>
          </li>
          <li className="py-1">
            <NavLink
              className={({ isActive }) =>
                isActive ? "text-white bg-gray-600 py-3 hover:bg-gray-600 hover:text-white" : `${theme ? 'text-black' : 'text-white'} py-3 hover:bg-gray-600 hover:text-white`
              }
              to="/dashboard/setting"
            >
              <i className="bx bx-cog text-xl"></i> Setting
            </NavLink>
          </li>
          <li className="absolute bottom-5 w-72">
            <button
              onClick={handleLogOut}
              className={`bg-transparent hover:bg-gray-600 hover:border-gray-600 border rounded-lg py-4 ${theme ? 'text-black border-black hover:text-white' : 'text-white border-white hover:text-white'} duration-300`}
            >
              <i className="bx bx-log-out"></i> Sign Out
            </button>
          </li>
        </ul>
      </div>
    </div>
  )
}
