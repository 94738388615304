import React, { useContext } from 'react'
import { InitializeContext } from '../App';

export default function AppLoader() {
          const { theme } = useContext(InitializeContext);
          return (
                    <div className='flex items-center justify-center h-screen'>
                              <span className={`loading loading-spinner loading-lg ${theme ? 'text-black' : 'text-white'}`}></span>
                    </div>
          )
}
