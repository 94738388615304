import React, { useContext, useState } from 'react'
import Logo from '../../assets/logo.png'
import { toast } from 'react-hot-toast';
import { signInWithEmailAndPassword } from 'firebase/auth';
import auth from '../../auth/firebase';
import { InitializeContext } from '../../App';

const initForm = {
          email: '',
          password: ''
}

export default function AuthScreen() {
          const { theme, toggleTheme } = useContext(InitializeContext);
          const [showPassword, setShowPassword] = useState<boolean>(false);
          const [loading, setLoading] = useState<boolean>(false);
          const [form, setForm] = useState(initForm);

          const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
                    setForm((oldForm) => ({
                              ...oldForm,
                              [e.target.name]: e.target.value
                    }));
          }

          const openInNewTab = (url: string) => {
                    window.open(url, "_blank", "noreferrer");
          };

          const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
                    e.preventDefault();
                    try {
                              setLoading(true);
                              const user = await signInWithEmailAndPassword(auth, form.email, form.password);
                              if (user) {
                                        toast.success(`Welcome to Account Manager ${user.user?.email}`);
                              } else {
                                        toast.error('Something went wrong');
                              }
                    } catch (err: string | any) {
                              const errorCode = err.code.split('auth/')[1].split('-').join(' ').toUpperCase();
                              toast.error(errorCode);
                              setLoading(false);
                    }
          }

          return (
                    <div className='flex flex-col items-center justify-center h-screen px-3 md:px-0'>
                              <div className={`flex flex-col items-center justify-center w-full md:w-1/2 lg:w-1/4 relative ${theme ? 'bg-white' : 'bg-[#222830]'} shadow-xl rounded-2xl px-5 py-8`}>
                                        <div onClick={toggleTheme} className='absolute top-3 right-4 cursor-pointer'>
                                                  {
                                                            theme ? (
                                                                      <i className='bx bx-moon text-xl'></i>
                                                            ) : (
                                                                      <i className='bx bx-sun text-xl'></i>
                                                            )
                                                  }
                                        </div>
                                        <div className='flex flex-col items-center gap-2'>
                                                  <img src={Logo} alt="Logo" className='w-10 md:w-16 rounded-md' />
                                                  <h1 className={`${theme ? 'text-black' : 'text-white'} text-2xl uppercase font-semibold mt-5`}>Account Manager</h1>
                                                  <p className={`text-center ${theme ? 'text-black' : 'text-white'} text-sm`}>“I need a six month vacation twice a year”</p>
                                        </div>
                                        <form onSubmit={handleSubmit} className='flex flex-col items-center gap-5 mt-10 w-full md:px-12'>
                                                  <input type="email" value={form.email} autoComplete='off' name='email' onChange={handleChange} placeholder="Enter username" className={`input input-bordered w-full bg-transparent placeholder:text-sm ${theme ? 'text-black focus:border-error' : 'text-white focus:border-warning'}`} />
                                                  <span className='w-full'>
                                                            <input type={showPassword ? "text" : "password"} autoComplete='off' value={form.password} name='password' onChange={handleChange} placeholder="Enter password" className={`input input-bordered w-full relative bg-transparent placeholder:text-sm ${theme ? 'text-black focus:border-error' : 'text-white focus:border-warning'}`} />
                                                            <span
                                                                      className="absolute -ml-9 mt-3 justify-center items-center text-xl cursor-pointer z-50"
                                                                      onClick={() => setShowPassword(!showPassword)}
                                                            >
                                                                      {showPassword ? (
                                                                                <i className="bx bx-show-alt"></i>
                                                                      ) : (
                                                                                <i className="bx bx-hide"></i>
                                                                      )}
                                                            </span>
                                                  </span>
                                                  <button disabled={loading || !form.email.trim() || !form.password.trim()} type='submit' className={`bg-transparent border border-gray-600 px-4 py-2 rounded-md disabled:text-gray-500 ${theme ? 'text-black' : 'text-white'}`}>Login</button>
                                        </form>
                              </div>
                              <div className='absolute bottom-5 px-3'>
                                        <p className={`${theme ? 'text-black' : 'text-white'} text-sm uppercase text-center`}>
                                                  Made with ❤️ by <span role='link' onClick={() => openInNewTab("https://toufiqhasankiron.com")} className={`${theme ? 'text-error hover:text-black' : 'text-warning hover:text-white'} cursor-pointer font-semibold duration-500`}>Toufiq Hasan Kiron</span>
                                        </p>
                              </div>
                    </div>
          )
}
