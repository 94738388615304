import React, { useState, useEffect, useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { BsArrowLeft, BsSearchHeart } from 'react-icons/bs';
import { BASE_API } from '../../../config';
import AppLoader from '../../../shared/AppLoader';
import { toast } from 'react-hot-toast';
import { BsClipboardHeart } from 'react-icons/bs';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import CategoryIcon from './CategoryIcon';
import CopiedClipboard from '../../../utils/CopiedClipboard';
import DateFormate from '../../../utils/DateFormate';
import useScrollToTop from '../../../hooks/useScrollToTop';
import { InitializeContext } from '../../../App';

export default function Accounts() {
          useScrollToTop();
          const { theme } = useContext(InitializeContext);
          const { categoryID } = useParams();
          const navigate = useNavigate();
          const [isFormOpen, setIsFormOpen] = useState<boolean>(false);
          const [isSearchFormOpen, setIsSearchFormOpen] = useState<boolean>(false);
          const [isShowNotesForm, setIsShowNotesForm] = useState<boolean>(false);
          const [searchedData, setSearchedData] = useState<any[]>([]);

          const { data, refetch, isLoading } = useQuery(["accounts"], async () => {
                    const res = await axios.get(`${BASE_API}/accounts/accounts-category?id=${categoryID}`);
                    return res?.data;
          });

          const accounts = data?.data;

          useEffect(() => {
                    if (accounts?.accounts?.length) {
                              setSearchedData(accounts?.accounts);
                    } else {
                              setSearchedData([]);
                    }
          }, [accounts?.accounts]);

          const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
                    e.preventDefault();
                    const formData = new FormData(e.currentTarget);
                    const username = formData.get('username');
                    const password = formData.get('password');
                    const sites = formData.get('sites');
                    const notes = formData.get('notes');

                    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
                    const isValidUrl = urlRegex.test(sites?.toString() || '');

                    if (!isValidUrl) {
                              return toast.error('Please enter a valid url');
                    }


                    if (!username || !password || !sites) {
                              return toast.error('Please fill all the fields');
                    } else {
                              const res = await axios.post(`${BASE_API}/accounts/account?categoryID=${categoryID}`, {
                                        account: {
                                                  username,
                                                  password,
                                                  url: sites,
                                                  notes
                                        }
                              });

                              if (res?.data?.status === 200) {
                                        setIsFormOpen(false);
                                        toast.success(res?.data?.message);
                                        refetch();
                              } else {
                                        toast.error(res?.data?.message);
                              }
                    }
          }

          useEffect(() => {
                    if (!searchedData?.length) {
                              setSearchedData(accounts?.accounts);
                    }
          }, [searchedData, accounts?.accounts]);

          const handleSearchAccount = async (e: any) => {
                    e.preventDefault();
                    const value = e.target.value.toLowerCase();
                    const filter = accounts?.accounts?.filter((item: any) => {
                              return (
                                        item.username.toLowerCase().includes(value)
                              );
                    });
                    setSearchedData(filter);
          };

          if (isLoading) {
                    return <AppLoader />
          }

          return (
                    <div className='py-5'>
                              <h1 className={`text-lg md:text-2xl ${theme ? 'text-black' : 'text-white'} font-semibold`}>
                                        All Accounts of <span className={`capitalize ${theme ? 'text-error' : 'text-warning'} cursor-pointer`} onClick={() => navigate(-1)}>{accounts?.category}</span> ({accounts?.accounts?.length})
                              </h1>
                              <div className='mt-5'>
                                        <div className='flex items-center w-1/5 md:w-1/12 lg:w-1/12 gap-2 cursor-pointer' onClick={() => navigate(-1)}>
                                                  <BsArrowLeft className={`${theme ? 'text-black' : 'text-white'} text-lg`} />
                                                  <h2 className={`${theme ? 'text-black' : 'text-white'} capitalize text-lg md:text-xl font-semibold`}>Back</h2>
                                        </div>
                                        <div className='flex justify-center md:justify-end gap-3 mt-5 md:mt-0 mb-10'>
                                                  <div hidden={isSearchFormOpen || isFormOpen || accounts?.accounts?.length === 0} onClick={() => setIsSearchFormOpen(true)} className={`${theme ? 'bg-white text-black' : 'bg-neutral hover:bg-gray-600 text-white'} shadow-lg cursor-pointer transition duration-500 px-4 py-3 rounded-lg`}>
                                                            <BsSearchHeart size={22} />
                                                  </div>
                                                  <button hidden={isFormOpen || isSearchFormOpen} className={`${theme ? 'bg-white text-black' : 'bg-neutral hover:bg-gray-600 text-white'} shadow-lg cursor-pointer transition duration-500 px-4 py-3 rounded-lg`}
                                                            onClick={() => setIsFormOpen(true)}
                                                  >Add New Account</button>
                                        </div>
                                        {
                                                  isSearchFormOpen && (
                                                            <div className={`${theme ? 'bg-white text-black' : 'bg-[#222830] text-white'} rounded-2xl shadow-lg p-5 w-full md:w-1/2 lg:w-1/3 mx-auto ${isSearchFormOpen && '-mt-10 md:mt-0'}`}>
                                                                      <div className='flex justify-between items-center'>
                                                                                <h1 className={`text-lg font-semibold ${theme ? 'text-black' : 'text-white'}`}>Search Account</h1>
                                                                                <button onClick={() => setIsSearchFormOpen(false)} className={`${theme ? 'bg-white text-black' : 'btn-neutral text-white'} shadow-lg btn btn-xs`}>
                                                                                          <AiOutlineCloseCircle />
                                                                                </button>
                                                                      </div>
                                                                      <div className='mt-3'>
                                                                                <div className='flex flex-col'>
                                                                                          <label htmlFor='username' className={`${theme ? 'text-black' : 'text-white'} mb-1`}>Username</label>
                                                                                          <input type='text' name='username' autoComplete='off' onChange={handleSearchAccount} placeholder='Type username' className={`${theme ? 'bg-white text-black' : 'bg-[#222830] text-white'} input input-bordered rounded-lg placeholder:text-sm`} />
                                                                                </div>
                                                                      </div>
                                                            </div>
                                                  )
                                        }
                                        {
                                                  isFormOpen && (
                                                            <div className={`${theme ? 'bg-white text-black' : 'bg-[#222830] text-white'} rounded-2xl shadow-lg p-5 w-full md:w-2/3 lg:w-1/3 mx-auto ${isFormOpen && '-mt-10 md:mt-0'}`}>
                                                                      <div className='flex justify-between items-center mb-5'>
                                                                                <h1 className={`text-lg font-semibold ${theme ? 'text-black' : 'text-white'}`}>Add New Account</h1>
                                                                                <button onClick={() => setIsFormOpen(false)} className={`${theme ? 'bg-white text-black' : 'btn-neutral text-white'} shadow-lg btn btn-xs`}>
                                                                                          <AiOutlineCloseCircle />
                                                                                </button>
                                                                      </div>
                                                                      <form onSubmit={handleSubmit}>
                                                                                <div className='flex flex-col'>
                                                                                          <label className={`text-sm ${theme ? 'text-black' : 'text-white'}`}>Username</label>
                                                                                          <input type="text" placeholder='Enter username' name='username' className={`${theme ? 'bg-white text-black' : 'bg-[#222830] text-white'} input input-bordered placeholder:text-sm rounded-lg mt-1`} />
                                                                                </div>
                                                                                <div className='flex flex-col mt-5'>
                                                                                          <label className={`text-sm ${theme ? 'text-black' : 'text-white'}`}>Password</label>
                                                                                          <input type="text" placeholder='Enter password' name='password' className={`${theme ? 'bg-white text-black' : 'bg-[#222830] text-white'} input input-bordered placeholder:text-sm rounded-lg mt-1`} />
                                                                                </div>
                                                                                <div className='flex flex-col mt-5'>
                                                                                          <label className={`text-sm ${theme ? 'text-black' : 'text-white'}`}>Sites</label>
                                                                                          <input type="text" placeholder='Enter site url' name='sites' className={`${theme ? 'bg-white text-black' : 'bg-[#222830] text-white'} input input-bordered placeholder:text-sm rounded-lg mt-1`} />
                                                                                </div>
                                                                                <div className={`flex flex-col items-center justify-center gap-2 mt-5 ${theme ? 'text-black' : 'text-white'}`}>
                                                                                          {isShowNotesForm ? 'Now type your notes!' : 'Want to Add Notes?'}
                                                                                          <input type="checkbox" onClick={() => setIsShowNotesForm(!isShowNotesForm)} className="toggle toggle-xs" checked={isShowNotesForm} />
                                                                                </div>
                                                                                {
                                                                                          isShowNotesForm && (
                                                                                                    <div className='flex flex-col'>
                                                                                                              <label className={`text-sm ${theme ? 'text-black' : 'text-white'}`}>Notes</label>
                                                                                                              <textarea style={{ resize: 'none', minHeight: '150px' }} typeof='text' placeholder='Enter notes (Optional)' name='notes' className={`${theme ? 'bg-white text-black' : 'bg-[#222830] text-white'} input input-bordered placeholder:text-sm pt-3 rounded-lg mt-1`} />
                                                                                                    </div>
                                                                                          )
                                                                                }
                                                                                <div className='flex justify-end mt-5'>
                                                                                          <button className={`${theme ? 'bg-white text-black' : 'btn-neutral text-white'} shadow-lg btn btn-sm`}
                                                                                          >Add</button>
                                                                                </div>
                                                                      </form>
                                                            </div>
                                                  )
                                        }
                                        {
                                                  searchedData?.length > 0 ? (
                                                            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 mt-5'>
                                                                      {
                                                                                searchedData?.map((account: any) => (
                                                                                          <div className={`${theme ? 'bg-white text-black' : 'bg-[#222830] text-white'} rounded-2xl shadow-lg p-5`} key={account._id}>
                                                                                                    <div className='flex justify-between items-center mb-5'>
                                                                                                              <div >
                                                                                                                        {CategoryIcon(accounts?.category, 50)}
                                                                                                              </div>
                                                                                                              <div className='flex justify-end items-center gap-2'>
                                                                                                                        <button onClick={() => navigate(`/dashboard/manageAccounts/${categoryID}/${account?._id}`)} className={`btn btn-sm ${theme ? 'bg-white text-black shadow-md' : 'btn-neutral text-white'}`}>
                                                                                                                                  Details
                                                                                                                        </button>
                                                                                                              </div>
                                                                                                    </div>
                                                                                                    <div className='flex justify-center mb-3'>
                                                                                                              <small className='text-xs text-center font-semibold'>Created At: <br />{DateFormate(account.createdAt)}</small>
                                                                                                    </div>
                                                                                                    {
                                                                                                              account?.updatedAt ? (
                                                                                                                        <div className='flex justify-center mb-5'>
                                                                                                                                  <small className='text-xs text-center font-semibold'>Updated At: <br />{DateFormate(account.updatedAt)}</small>
                                                                                                                        </div>
                                                                                                              ) : (
                                                                                                                        <div className='flex justify-center mb-5'>
                                                                                                                                  <small className='text-xs text-center font-semibold'>Updated At: <br />{DateFormate(account.createdAt)}</small>
                                                                                                                        </div>
                                                                                                              )
                                                                                                    }
                                                                                                    <div className='flex flex-col gap-3'>
                                                                                                              <div className='flex justify-between select-none cursor-not-allowed'>
                                                                                                                        <h1 className='text-xs md:text-sm font-semibold'>Username: <br /><span className={`${theme ? 'text-error' : 'text-warning'}`}>{account?.username}</span></h1>
                                                                                                                        <BsClipboardHeart onClick={() => CopiedClipboard(account?.username)} size={18} className='text-white cursor-pointer' />
                                                                                                              </div>
                                                                                                              <div className='flex justify-between cursor-pointer'>
                                                                                                                        <h1 className='text-xs md:text-sm font-semibold' onClick={() => navigate(`/dashboard/manageAccounts/${categoryID}/${account?._id}`)}>Password: <br /><span className={`${theme ? 'text-error' : 'text-warning'}`}>{
                                                                                                                                  '********'
                                                                                                                        }</span></h1>
                                                                                                              </div>
                                                                                                              <div className='flex justify-between'>
                                                                                                                        <h1 className='text-xs md:text-sm font-semibold'>Sites: <br />
                                                                                                                                  <a href={account?.url} target='_blank' rel='noreferrer'>
                                                                                                                                            <span className={`${theme ? 'text-error' : 'text-warning'}`}>
                                                                                                                                                      {account?.url}
                                                                                                                                            </span></a>
                                                                                                                        </h1>
                                                                                                                        <BsClipboardHeart onClick={() => CopiedClipboard(account?.url)} size={18} className='text-white cursor-pointer' />
                                                                                                              </div>
                                                                                                              {account?.notes ? (
                                                                                                                        <div className='select-none cursor-not-allowed'>
                                                                                                                                  <h1 className='text-xs md:text-sm font-semibold'>Notes: <br /><span className={`${theme ? 'text-error' : 'text-warning'}`}>
                                                                                                                                            {account?.notes?.length > 30 ? (
                                                                                                                                                      <span>{account?.notes?.slice(0, 30)}...</span>
                                                                                                                                            ) : (
                                                                                                                                                      <span>{account?.notes}</span>
                                                                                                                                            )}
                                                                                                                                  </span></h1>
                                                                                                                        </div>
                                                                                                              ) : (
                                                                                                                        <div className='select-none cursor-not-allowed'>
                                                                                                                                  <h1 className='text-xs md:text-sm font-semibold'>Notes: <br /><span className={`text-red-600`}>Oops! No Notes Found</span></h1>
                                                                                                                        </div>
                                                                                                              )
                                                                                                              }
                                                                                                    </div>
                                                                                          </div>

                                                                                ))
                                                                      }

                                                            </div>
                                                  ) : (
                                                            <div className={`${theme ? 'bg-white' : 'bg-[#222830]'} rounded-2xl shadow-lg p-5 mt-10 w-full md:w-1/2 lg:w-1/3 mx-auto`}>
                                                                      <h1 className={`text-lg font-semibold text-center ${theme ? 'text-black' : 'text-white'}`}>No Accounts Found</h1>
                                                            </div>
                                                  )
                                        }
                              </div>
                    </div>
          )
}
